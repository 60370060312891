@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/tools/mixins/typography';

.article {
  // the bold line items at the top of the article are used as headlines
  > ol > li {
    &::marker,
    b {
      @include typography.headlinesH5TypographyStyles;
    }
  }
}
