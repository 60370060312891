@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/fonts';
@use '$styles/variables/colors';
@use '$styles/variables/spacings';

.component {
    display: flex;
    align-items: center;
    gap: spacings.$XXS;
    flex-basis: 25%;
}

.serviceTitle {
    font: fonts.$paragraphM;
    margin: spacings.$XXXS;
}

.serviceIconCircle {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary-color, colors.$purple500);

    border-radius: 50px;
    background: var(--primary-color-neutral, colors.$purple200);
}

.serviceIcon {
    color: var(--primary-color,  colors.$purple500);
}