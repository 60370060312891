@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/spacings";
@use '$styles/mediaqueries';


@include mediaqueries.mediumUp {
  aside {
    > .sticky {
      position: sticky;
      top: spacings.$XXXL + spacings.$L;

    }
  }
}