@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/fonts';
@use '$styles/variables/spacings';
@use '$styles/mediaqueries';

.hero {
  background: linear-gradient(90deg, #5739D8 0%, #3A1DA7 100%);
  min-height: 200px;
  width: 100%;
  display: flex;
  padding: 0;
  overflow: hidden;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: spacings.$M;
  gap: 24px;

  @include mediaqueries.smallUp {
    padding: spacings.$M spacings.$XL;
  }

  h1 {
    max-width: 1080px;
    margin: auto 0;
    color: colors.$white;
    font-family: Jokker;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.56px;
    z-index: 1;

    @include mediaqueries.smallUp {
      font-size: 40px;
      line-height: 44px;
      letter-spacing: -1.4px;
    }
  }

  .faqIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    height: spacings.$XXXL;
    width: spacings.$XXXL;
    min-width: spacings.$XXXL;
    min-height: spacings.$XXXL
  }
}