// @DEPRECATED use the typography mixins instead
@use '$styles/variables/fonts';

// @TODO find a way to make this and the typography mixins file DRY

// DISPLAY
%displayMTypographyStyles {
  font: fonts.$displayM;
  font-kerning: normal;
  letter-spacing: -3.2px;
}

// HEADLINES
%headlinesH1TypographyStyles {
  font: fonts.$headlinesH1;
  font-kerning: normal;
  letter-spacing: -1.68px;
  margin-top: 0;
  margin-bottom: 0.5em;
}
%headlinesH2TypographyStyles {
  font: fonts.$headlinesH2;
  font-kerning: normal;
  letter-spacing: -1.4px;
  margin-top: 0;
  margin-bottom: 0.5em;
}
%headlinesH3TypographyStyles {
  font: fonts.$headlinesH3;
  font-kerning: normal;
  letter-spacing: -1.12px;
  margin-top: 0;
  margin-bottom: 0.5em;
}
%headlinesH4TypographyStyles {
  font: fonts.$headlinesH4;
  font-kerning: normal;
  letter-spacing: -0.84px;
  margin-top: 0;
  margin-bottom: 0.5em;
}
%headlinesH5TypographyStyles {
  font: fonts.$headlinesH5;
  font-kerning: normal;
  letter-spacing: -0.48px;
  margin-top: 0;
  margin-bottom: 0.5em;
}
%headlinesH6TypographyStyles {
  font: fonts.$headlinesH6;
  font-kerning: normal;
  letter-spacing: -0.44px;
  margin-top: 0;
  margin-bottom: 0.5em;
}

// LABELS
%labelLTypographyStyles {
  font: fonts.$labelL;
  font-kerning: normal;
}
%labelMTypographyStyles {
  font: fonts.$labelM;
  font-kerning: normal;
}
%labelSTypographyStyles {
  font: fonts.$labelS;
  font-kerning: normal;
}
%labelXSTypographyStyles {
  font: fonts.$labelXS;
  font-kerning: normal;
}

// PARAGRAPH
%paragraphLTypography {
  font: fonts.$paragraphL;
  font-kerning: normal;
}
%paragraphMTypography {
  font: fonts.$paragraphM;
  font-kerning: normal;
}
%paragraphSTypography {
  font: fonts.$paragraphS;
  font-kerning: normal;
}
%paragraphXSTypography {
  font: fonts.$paragraphXS;
  font-kerning: normal;
}
