@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/spacings";
@use '$styles/variables/colors';

.main {
  background-color: colors.$white;
}

.col {
  margin-top: spacings.$XL;
}

.row {
  margin-bottom: spacings.$L;
}

