@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/colors';
@use '$styles/variables/fonts';
@use '$styles/variables/spacings';

.component {
  .GarageName {
    font-family: fonts.$campaignFontFamily;
  }
}

.header {
  display: flex;
  flex-direction: column;

  @include mediaqueries.upToMedium {
    align-items: center;
  }
}

.TitleSection {
  padding-top: spacings.$S;
}

.header__img-wrapper {
  margin-left: auto;
}

.header__img {
  margin-bottom: spacings.$XS;
}

.GarageName {
  color: colors.$white;

  @include mediaqueries.upToMedium {
    margin-bottom: spacings.$XXS !important;
  }
}

.Subtitle {
  color: colors.$white;
  font: fonts.$labelL;
  font-weight: 600;
  margin: 0;
  @include mediaqueries.upToMedium {
    text-align: center;
  }
}

.ReviewSection {
  display: flex;
  align-items: center;
  color: colors.$white;
  margin-top: spacings.$S;
  @include mediaqueries.upToMedium {
    margin-bottom: spacings.$M;
  }
}

.ReviewStarsContainer {
  margin-left: spacings.$XXS;
}

.RatingNumber {
  font-family: fonts.$headlinesFontFamily;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}

.RatingNumber,
.ReviewStars,
.ReviewText {
  line-height: 1;
}

.ReviewText {
  font-family: fonts.$paragraphS;
}

.RatingNumber {
  font-family: fonts.$headlinesFontFamily;
  font-size: 16px;
  font-weight: 600;
  margin-right: spacings.$XXXS;
}

.ReviewStars {
  margin-right: 4px;
}

.garageDetailsSection {
  @include mediaqueries.upToMedium {
    display: none;
  }
  @include mediaqueries.mediumUp {
    margin-top: spacings.$XXL;
  }
}

.ReviewStars img {
  margin-right: 2px;
}

.ReviewText {
  font-family: fonts.$paragraphFontFamily;
  font-size: 12px;
}

.Description {
  display: none;
  margin-top: spacings.$M;

  @include mediaqueries.mediumUp {
    display: block;
  }
}

.ctaSection {
  display: inline-block;

  @include mediaqueries.upToMedium {
    display: none;
  }
}

.toggleButton {
  display: none;

  @include mediaqueries.mediumUp {
    display: block;
    margin-top: spacings.$L;
  }
}

.poweredBySection {
  @include mediaqueries.mediumUp {
    margin-top: spacings.$M;
    align-items: center;
    justify-content: center;
  }
}

.hideCtaSection {
  display: none;
}

.NonFixtermessage {
  color: colors.$white;
  font: fonts.$labelS;
  margin: spacings.$M 0;
}