/**
 * Set a consistent color across anchor states.
 */
@mixin anchorColor($color) {
    color: $color;
    &:active,
    &:focus,
    &:visited {
        color: $color;
    }
}

/**
 * Strip UL of default list styles.
 */
@mixin cleanList {
    margin: 0;
    padding: 0;
    list-style: none;
}
