@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use "$styles/variables/colors";

.main {
  background-color: colors.$purple100;
  @include mediaqueries.mediumUp {
    padding-bottom: 58px;
  }
}
