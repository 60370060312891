@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/radii';
@use '$styles/variables/colors';
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';

.card {
  background-color: colors.$purple200;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: spacings.$L 20px spacings.$M;
  position: relative;
  h6 {
    color: colors.$white;
    font-family: Jokker;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.4px;
    margin-bottom: 16px;
    @include mediaqueries.smallUp {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.44px;
    }
  }
}

.dark {
  background-color: colors.$purple500;
}

.light {
  background-color: colors.$purple200;
  h6 {
    color: colors.$purple800;
  }
}

.reviewContainer {
  margin-top: 13px;
  text-align: center;
}

.googleReviewsSummary {
  display: flex;
  justify-content: center;
  a {
    gap: 5px;
  }
  p {
    margin: 0;
  }
}

.group {
  z-index: 1;
}

.linesContainer {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.lines {
  position: absolute;
  transform: translate(10%, -130%);
  rotate: 205deg;

  @include mediaqueries.smallUp {
    transform: translate(25%, -120%) scale(1);
  }
}

.logo {
  position: absolute;
  top: -25px;
  left: calc(50% - 25px);
}

.trustpilot {
  height: 37px;
  .tp-widget-wrapper {
    height: 37px !important;
  }
  @include mediaqueries.smallUp {
    height: 20px;
  }
}
