@forward "$styles/variables/colors";
@forward "$styles/variables/fonts";
@forward "$styles/variables/shadows";
@forward "$styles/variables/elevation";
@forward "$styles/tools/mixins/typography";
@forward "$styles/tools/mixins/skeleton";
@forward "$styles/tools/mixins/elevations";

$baseFontSize: 16;

$px_1: (1 / $baseFontSize) * 1rem; // 0.0625rem;   // 1px   // tailwind token N/A
$px_2: (2 / $baseFontSize) * 1rem; // 0.125rem;    // 2px   // tailwind token 0.5
$px_4: (4 / $baseFontSize) * 1rem; // 0.25rem;     // 4px   // tailwind token 1
$px_6: (6 / $baseFontSize) * 1rem; // 0.375rem;    // 6px   // tailwind token 1.5
$px_8: (8 / $baseFontSize) * 1rem; // 0.5rem;      // 8px   // tailwind token 2
$px_10: (10 / $baseFontSize) * 1rem; // 0.625rem;   // 10px   // tailwind token 2.5 
$px_12: (12 / $baseFontSize) * 1rem; // 0.75rem;    // 12px   // tailwind token 3
$px_14: (14 / $baseFontSize) * 1rem; // 0.875rem;   // 14px   // tailwind token 3.5
$px_16: (16 / $baseFontSize) * 1rem; // 1rem;       // 16px   // tailwind token 4
$px_20: (20 / $baseFontSize) * 1rem; // 1.25rem;    // 20px   // tailwind token 5
$px_24: (24 / $baseFontSize) * 1rem; // 1.5rem;     // 24px   // tailwind token 6
$px_28: (28 / $baseFontSize) * 1rem; // 1.75rem;    // 28px   // tailwind token 7
$px_32: (32 / $baseFontSize) * 1rem; // 2rem;       // 32px   // tailwind token 8
$px_36: (36 / $baseFontSize) * 1rem; // 2.25rem;    // 36px   // tailwind token 9
$px_40: (40 / $baseFontSize) * 1rem; // 2.5rem;     // 40px   // tailwind token 10
$px_44: (44 / $baseFontSize) * 1rem; // 2.75rem;    // 44px   // tailwind token 11
$px_48: (48 / $baseFontSize) * 1rem; // 3rem;       // 48px   // tailwind token 12
$px_56: (56 / $baseFontSize) * 1rem; // 3.5rem;     // 56px   // tailwind token 14
$px_64: (64 / $baseFontSize) * 1rem; // 4rem;       // 64px   // tailwind token 16
$px_80: (80 / $baseFontSize) * 1rem; // 5rem;       // 80px   // tailwind token 20
$px_96: (96 / $baseFontSize) * 1rem; // 6rem;       // 96px   // tailwind token 24
$px_112: (112 / $baseFontSize) * 1rem; // 7rem;      // 112px   // tailwind token 28
$px_128: (128 / $baseFontSize) * 1rem; // 8rem;      // 128px   // tailwind token 32
$px_144: (144 / $baseFontSize) * 1rem; // 9rem;      // 144px   // tailwind token 36

// Spacings: use for margins, paddings and gaps.
$space0-5: $px_2;
$space1: $px_4;
$space1-5: $px_6;
$space2: $px_8;
$space2-5: $px_10;
$space3: $px_12;
$space3-5: $px_14;
$space4: $px_16;
$space5: $px_20;
$space6: $px_24;
$space7: $px_28;
$space8: $px_32;
$space9: $px_36;
$space10: $px_40;
$space11: $px_44;
$space12: $px_48;
$space14: $px_56;
$space16: $px_64;
$space20: $px_80;
$space24: $px_96;
$space28: $px_112;
$space32: $px_128;
$space36: $px_144;

:export {
  space0-5: $space0-5;
  space1: $space1;
  space1-5: $space1-5;
  space2: $space2;
  space2-5: $space2-5;
  space3: $space3;
  space3-5: $space3-5;
  space4: $space4;
  space5: $space5;
  space6: $space6;
  space7: $space7;
  space8: $space8;
  space9: $space9;
  space10: $space10;
  space11: $space11;
  space12: $space12;
  space14: $space14;
  space16: $space16;
  space20: $space20;
  space24: $space24;
  space28: $space28;
  space32: $space32;
  space36: $space36;
}

// radii, use for border-radius
$radius1: $px_4;
$radius2: $px_8;
$radius2-5: $px_8;
$radius3: $px_12;
$radius4: $px_16;
$radius5: $px_20;
$radius6: $px_24;
$radius8: $px_32;

:export {
  radius1: $radius1;
  radius2: $radius2;
  radius2: $radius2;
  radius3: $radius3;
  radius4: $radius4;
  radius5: $radius5;
  radius6: $radius6;
  radius8: $radius8;
}

// Lines, used for any property that sets line such as a border or outline etc
$line0-25: $px_1;
$line0-5: $px_2;
$line1: $px_4;
$line2: $px_8;

:export {
  line0-25: $line0-25;
  line0-5: $line0-5;
  line1: $line1;
  line2: $line2;
}