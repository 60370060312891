$shadow1:
  0px 1px 4px rgba(58, 53, 51, 0.08),
  0px 0px 1px rgba(58, 53, 51, 0.25);

$shadow2:
  0px 2px 12px rgba(58, 53, 51, 0.1), 0px 0px 2px rgba(58, 53, 51, 0.2);

$shadow3:
  0px 4px 20px rgba(58, 53, 51, 0.15), 0px 0px 3px rgba(58, 53, 51, 0.1);

$shadow4:
  0px 8px 40px rgba(58, 53, 51, 0.2), 0px 0px 4px rgba(58, 53, 51, 0.1);

:export {
  shadow1: $shadow1;
  shadow2: $shadow2;
  shadow3: $shadow3;
  shadow4: $shadow4;
}

// @deprecated
$elevationLevel1BoxShadow: $shadow1;
$elevationLevel2BoxShadow: $shadow2;
$elevationLevel3BoxShadow: $shadow3;
$elevationLevel4BoxShadow: $shadow4;