@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';
@use '$styles/variables/colors';

.component {
  &.boxed {
    padding-top: spacings.$M + spacings.$L;
    background-color: colors.$white;

    @include mediaqueries.smallUp {
      padding-top: spacings.$L;
    }
  }
}

.repairs {
  max-width: 659px;
  margin: auto;
}
