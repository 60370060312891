@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/colors';
@use '$styles/variables/radii';
@use '$styles/variables/spacings';
@use '$styles/variables/fonts';

.component {
  padding: spacings.$L 0;
  background: var(--background-color, colors.$purple500);

  @include mediaqueries.upToMedium {
    padding: spacings.$M 0 60px 0;
    position: relative;
    z-index: 1;
  }
}

.ImageWrapper {
  position: relative;
  height: 200px;

  @include mediaqueries.mediumUp {
    margin: 0;
    height: 100%;
  }
}

.GarageImage {
  object-fit: cover;
  border-radius: radii.$M;
}

.CTASection {
  display: flex;
  justify-content: center;
  margin-top: spacings.$M;

  @include mediaqueries.mediumUp {
    display: none;
  }
}

.poweredBySection {
  margin-top: spacings.$M;
  align-items: center;
  justify-content: center;
  @include mediaqueries.mediumUp {
    display: none;
  }
}

.NonFixtermessage {
  text-align: center;
  color: colors.$white;
  font: fonts.$labelS;
  margin: spacings.$M 0;

  @include mediaqueries.mediumUp {
    display: none;
  }
}