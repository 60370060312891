@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use "$styles/variables/elevation";
@use "$styles/tools/mixins/utils";
@use "$styles/tools/mixins/typography";

.component {
    position: relative;
    text-align: left;

    &:focus-within {
        .title-closed {
            display: none;
        }
        .title-opened {
            display: inline;
        }

        .dropdown-content {
            display: block;
        }
        .dropdown-toggle svg {
            transform: rotate(180deg);
        }
    }

    .title-opened {
        display: none;
    }

    .dropdown-toggle {
        display: inline-flex;
        align-items: center;
        gap: 5px;
    }

    .dropdown-content {
        min-width: 180px;
        display: none;
        position: absolute;
        right: 0;
        z-index: elevation.$top;

        margin-top: 10px;
        padding: 8px;
        background-color: colors.$white;
        border: 1px solid colors.$neutral200;
        border-radius: 8px;
        white-space: nowrap;

        @include typography.labelMTypographyStyles;

        ul {
            @include utils.cleanList();
            li {
                padding: 8px;
            }
            a {
                text-decoration: none;
                @include utils.anchorColor(colors.$neutral800);
            }
        }
    }
}
