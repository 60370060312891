@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/colors';
@use '$styles/variables/spacings';

.footer {
  padding: spacings.$XL 0;
  background: var(--background-color, colors.$purple500);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__section p:first-of-type {
  color: white;
}

.footer__section {
  &-copyright {
    text-align: center;
  }
}

.footer__section:nth-child(2) {
  padding: spacings.$XL 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__section:last-of-type {
  color: colors.$white;
}

.footer__link {
  color: colors.$white;
  text-decoration: underline;
  margin-bottom: spacings.$XXXS;
}

.footer__link:last-of-type {
  margin-bottom: 0;
}
