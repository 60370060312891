@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/spacings';
@use '$styles/variables/colors';

.component {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background-color: colors.$white;
}

.TrustSectionWrapper {
  justify-content: center;
  align-items: center;

  display: flex;
  flex-direction: column;

  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;

  min-width: 20%;

  @include mediaqueries.upToMedium {
    padding: spacings.$S;
  }
  @include mediaqueries.mediumUp {
    margin: spacings.$S 0;
    padding: 0 spacings.$S;
    border-right: 1px solid colors.$neutral200;
    flex-basis: 0;
  }

  &:last-of-type {
    border-right: 0;
  }
}

.TrustIconSection {
  border-top: 1px solid colors.$neutral200;

  @include mediaqueries.mediumUp {
    border-top: 0;
  }
}

.TrustTitle {
  @include typography.labelMTypographyStyles;
  color: var(--primary-color, colors.$neutral800);
}

.TrustText {
  text-align: center;
  @include typography.paragraphSTypography;
  color: colors.$neutral400;
}

.GarageIcon {
  color: var(--primary-color, colors.$neutral800);
}