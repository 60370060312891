@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';
@use '$styles/tools/mixins/typography';
@use "$styles/variables/shadows";
@use '$styles/variables/fonts';

.component {
    position: fixed;
    display: flex;
    top: 0;
    width: 100%;
    height: 60px;
    z-index: 102; /** Any value bigger than MegaNavBar(101) */

    @include mediaqueries.smallUp {
        margin-top: 80px;
    }

    @include mediaqueries.mediumUp{
        display: none;
    }
}

.stickyHeaderTitleSection {
    width: 62%;
    background-color: colors.$purple500;
    display: flex;
    justify-content: flex-start; /* Align items horizontally to the left */
    align-items: center; /* Align items vertically to the center */
    position: relative; /* Make sure the positioning context is set */
}

.title {
    font: fonts.$labelM;
    width: 60%;
    color: colors.$white;
    position: absolute;
    left: 12px;
}

.scrollToMapSection {
    width: 38%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.mapBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.scrollToMapCTA {
    position: relative;
    z-index: 1;
    background-color: colors.$white;
    height: 24px;
    color: colors.$neutral800;
    box-shadow: shadows.$elevationLevel1BoxShadow;
}