@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/spacings";
@use '$styles/mediaqueries';

.component {
  max-width: calc(1440px - spacings.$XL);
  width: calc(100% - spacings.$XL);
  margin-left: auto;
  margin-right: auto;
  
  @include mediaqueries.mediumUp {
    max-width: calc(1440px - spacings.$XXXL);
    width: calc(100% - spacings.$XXXL);
  }
}

article {
  .component {
    max-width: unset;
    width: unset;
    margin-right: unset;
    margin-left: unset;
  }
}
