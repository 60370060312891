@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/radii';
@use '$styles/variables/colors';
@use '$styles/variables/spacings';
@use '$styles/tools/mixins/typography';

.card {
  display: flex;
  justify-content: space-between;
  background-color: colors.$purple100;
  padding: spacings.$S;
  position: relative;
  cursor: pointer;

  .label {
    display: flex;
    align-items: center;
    gap: 12px;
    color: colors.$purple800;
    @include typography.labelMTypographyStyles;
    line-height: 18px;
    letter-spacing: -0.14px;
    cursor: pointer;
  }
}

.ellipse {
  display: flex;
  height: 32px;
  width: 32px;
  border-radius: radii.$M;
  background-color: colors.$purple600;
}

.arrow {
  color: colors.$white;
  margin: auto;
  rotate: 90deg;
}
