@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/tools/mixins/typography';
@use '$styles/variables/colors';
@use '$styles/variables/spacings';
@use '$styles/mediaqueries';
@use '$styles/variables/radii';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    @include typography.headlinesH1TypographyStyles;
    margin: spacings.$XXXL 0;
  }
}

.error {
  color: red;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  label {
    @include typography.labelMTypographyStyles;
    margin-bottom: 0.5rem;
  }

  input {
    @include typography.paragraphMTypography;
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: radii.$S;
    margin-bottom: 1rem;
    &:focus {
      outline: none;
      border: 1px solid colors.$purple500;
      box-shadow: 0px 0px 0px 2px colors.$purple300;
    }
  }

  button {
    align-self: center;
  }
}

.submitted {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: spacings.$XXXL;
  h1 {
    @include typography.headlinesH1TypographyStyles;
    margin: spacings.$XXXL 0;
  }
}

.card {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: spacings.$L;
  background-color: colors.$white;
}