@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/radii';
@use '$styles/variables/shadows';
@use '$styles/variables/spacings';
@use '$styles/mediaqueries';
@use "$styles/tools/mixins/typography";

.container {
  text-align: center;
  position: relative;

  padding: 32px 10px;
}

.containerLarge {
  @include mediaqueries.mediumUp {
    padding: 128px 0 32px 0;
  }
}

.heading,
.subheading {
  span {
    display: block;
  }
}

.heading {
  @include typography.headlinesH4TypographyStyles;

  @include mediaqueries.mediumUp {
    @include typography.headlinesH1TypographyStyles;
  }
}

.subheading {
  @include typography.headlinesH6TypographyStyles;

  @include mediaqueries.mediumUp {
    @include typography.headlinesH5TypographyStyles;
  }
}

.searchCard {
  position: relative;
  max-width: 610px;
  margin: spacings.$M auto 0;
  padding: spacings.$M;
  border-radius: radii.$S;
  box-shadow: shadows.$elevationLevel3BoxShadow;
  background: colors.$white;

  @include mediaqueries.mediumUp {
    background: colors.$white;
  }
}

.searchContainer {
  position: relative;
  padding: spacings.$XS spacings.$M;
  border-radius: radii.$S;
  border: 1px solid colors.$neutral200;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: text;
}

.searchHeader {
  @include typography.headlinesH6TypographyStyles;
  color: colors.$purple500;
}

.input {
  width: 100%;
  border: none;
  @include typography.paragraphMTypography;

  &:focus {
    outline: none;
  }
}

.focussed {
  border-color: colors.$purple500;
  box-shadow: rgb(195, 182, 251) 0px 0px 0px 2px;
}

.inputIcon {
  margin-right: spacings.$XS;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancelIcon {
  margin-left: spacings.$XS;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.suggestions {
  max-height: 310px;
  position: absolute;
  top: 52px;
  left: 0;
  width: 100%;
  border: 1px solid colors.$neutral200;
  border-radius: radii.$S;
  background: colors.$white;
  box-shadow: shadows.$elevationLevel4BoxShadow;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  margin: 0;
  z-index: 10;
}

.suggestionItemContainer {
  padding: spacings.$S spacings.$M;
  border-bottom: 1px solid colors.$neutral200;
  cursor: pointer;

  &:hover {
    background-color: colors.$neutral100;
  }
}

.suggestionItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    margin: 0;
  }
}

.loading {
  height: 310px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: spacings.$S;
}

.searchError {
  height: 310px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: spacings.$S;

  span {
    display: block;
  }
}


:export {
  colorNeutral300: colors.$neutral300;
  colorNeutral600: colors.$neutral600;
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
