@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';

.component {
  position: relative;

  .GarageMapPosition {
    @include mediaqueries.mediumUp {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: calc(100vh - 80px);
    }
  }

  .GarageMapPositionShort {
    @include mediaqueries.mediumUp {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: calc(100% - 25px);
    }
  }

  .GarageMapPositionStycky {
    @include mediaqueries.mediumUp {
      position: sticky;
      top: 80px;
    }
  }

  .NoGarageMapPositionStycky {
    position: initial;
  }


  .BottomMapShort {
    height: calc(100% - 25px);
  }

  .BottomMap {
    top: calc(100% - 100vh + 80px - 25px);
  }

  .NoGarages {
    min-height: 400px;
    padding-top: spacings.$L;
  }

  .GarageListWrapper {
    overflow: hidden;
    max-height: 2000px;
    position: relative;

    &.show {
      max-height: 100%;
    }
  }

    .hideMask {
      position: absolute;
      bottom: 0px;
      height: 200px;
      width: 100%;
      background: linear-gradient(to bottom, transparent 0, white 80%, white 100%);
    }

}
