@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/spacings';
@use '$styles/variables/fonts';
@use '$styles/variables/colors';
@use '$styles/mediaqueries';


.component {
    padding: spacings.$S spacings.$M spacings.$S;
}

.Title {
    @include mediaqueries.mediumUp {
      text-align: center;
      padding-bottom: spacings.$XL;
    }
    @include mediaqueries.upToMedium {
      text-align: left;
      font: fonts.$headlinesH5 !important;
    }
}

.description {
    color: colors.$neutral600;
    font: fonts.$paragraphM;
}

.TrustSection {
  @include mediaqueries.upToMedium {
    box-shadow: unset;
  }
  @include mediaqueries.mediumUp {
    margin-top: spacings.$XL;
  }
}