@use '$styles/variables/fonts';

// @TODO find a way to make this and the typography extends file DRY

// DISPLAY
@mixin displayMTypographyStyles {
  font: fonts.$displayM;
  font-kerning: normal;
  letter-spacing: -3.2px;
}

// HEADLINES
@mixin headlinesH1TypographyStyles {
  font: fonts.$headlinesH1;
  font-kerning: normal;
  letter-spacing: -1.68px;
  margin-top: 0;
  margin-bottom: 0.5em;
}
@mixin headlinesH2TypographyStyles {
  font: fonts.$headlinesH2;
  font-kerning: normal;
  letter-spacing: -1.4px;
  margin-top: 0;
  margin-bottom: 0.5em;
}
@mixin headlinesH3TypographyStyles {
  font: fonts.$headlinesH3;
  font-kerning: normal;
  letter-spacing: -1.12px;
  margin-top: 0;
  margin-bottom: 0.5em;
}
@mixin headlinesH4TypographyStyles {
  font: fonts.$headlinesH4;
  font-kerning: normal;
  letter-spacing: -0.84px;
  margin-top: 0;
  margin-bottom: 0.5em;
}
@mixin headlinesH5TypographyStyles {
  font: fonts.$headlinesH5;
  font-kerning: normal;
  letter-spacing: -0.48px;
  margin-top: 0;
  margin-bottom: 0.5em;
}
@mixin headlinesH6TypographyStyles {
  font: fonts.$headlinesH6;
  font-kerning: normal;
  letter-spacing: -0.44px;
  margin-top: 0;
  margin-bottom: 0.5em;
}

// LABELS
@mixin labelLTypographyStyles {
  font: fonts.$labelL;
  font-kerning: normal;
}
@mixin labelMTypographyStyles {
  font: fonts.$labelM;
  font-kerning: normal;
}
@mixin labelSTypographyStyles {
  font: fonts.$labelS;
  font-kerning: normal;
}
@mixin labelXSTypographyStyles {
  font: fonts.$labelXS;
  font-kerning: normal;
}

// PARAGRAPH
@mixin paragraphLTypography {
  font: fonts.$paragraphL;
  font-kerning: normal;
}
@mixin paragraphMTypography {
  font: fonts.$paragraphM;
  font-kerning: normal;
}
@mixin paragraphSTypography {
  font: fonts.$paragraphS;
  font-kerning: normal;
}
@mixin paragraphXSTypography {
  font: fonts.$paragraphXS;
  font-kerning: normal;
}
