@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/spacings";

.invalidContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin: spacings.$XXXL 0;
  }
}