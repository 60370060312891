@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/spacings';
@use '$styles/variables/fonts';
@use '$styles/variables/colors';

.GarageDetail {
    display: flex;
    margin-bottom: spacings.$S;

    .GarageDetailIcon {
      padding-top: 2px;
    }

    .GarageDetailContent {
      padding-left: spacings.$XS;

      .GarageDetailContentValue {
        font-family: fonts.$paragraphS;
        padding-bottom: spacings.$XXXS;
        color: colors.$neutral200;
      }
    }
}

.iconColor {
  color: colors.$neutral200;
}