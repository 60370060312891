@use "$styles/variables/breakpoints";

@mixin makeContainer($name) {
  container-type: inline-size;
  @if $name {
    container-name: #{$name};
  }
}

@mixin extraSmallOnly($containerName) {
  @container #{$containerName} (max-width: #{((breakpoints.$smallBreakpoint - (breakpoints.$smallMargins * 2)) - 1px)}) {
    @content;
  }
}

@mixin extraSmallUp($containerName) {
  @container #{$containerName} (min-width: #{(breakpoints.$extraSmallBreakpoint - (breakpoints.$extraSmallMargins * 2))}) {
    @content;
  }
}

@mixin smallOnly($containerName) {
  @container #{$containerName} (min-width: #{(breakpoints.$smallBreakpoint - (breakpoints.$smallMargins * 2))}) and (max-width: #{((breakpoints.$desktopBreakpoint - (breakpoints.$desktopMargins * 2)) - 1px)}) {
    @content;
  }
}

@mixin upToSmall($containerName) {
  @container #{$containerName} (max-width: #{((breakpoints.$smallBreakpoint - (breakpoints.$smallMargins * 2)) - 1px)}) {
    @content;
  }
}

@mixin smallUp($containerName) {
  @container #{$containerName} (min-width: #{(breakpoints.$smallBreakpoint - (breakpoints.$smallMargins * 2))}) {
    @content;
  }
}

@mixin mediumOnly($containerName) {
  @container #{$containerName} (min-width: #{(breakpoints.$mediumBreakpoint - (breakpoints.$mediumMargins * 2))}) and (max-width: #{((breakpoints.$widescreenBreakpoint - (breakpoints.$widescreenMargins * 2)) - 1px)}) {
    @content;
  }
}

@mixin upToMedium($containerName) {
  @container #{$containerName} (max-width: #{((breakpoints.$mediumBreakpoint - (breakpoints.$mediumMargins * 2)) - 1px)}) {
    @content;
  }
}

@mixin mediumUp($containerName) {
  @container #{$containerName} (min-width: #{(breakpoints.$mediumBreakpoint - (breakpoints.$mediumMargins * 2))}) {
    @content;
  }
}


@mixin largeOnly($containerName) {
  @container #{$containerName} (min-width: #{(breakpoints.$largeBreakpoint - (breakpoints.$largeMargins * 2))}) and (max-width: #{((breakpoints.$extraLargeBreakpoint - (breakpoints.$extraLargeMargins * 2)) - 1px)}) {
    @content;
  }
}


@mixin upToLarge($containerName) {
  @container #{$containerName} (max-width: #{((breakpoints.$largeBreakpoint - (breakpoints.$largeMargins * 2)) - 1px)}) {
    @content;
  }
}

@mixin largeUp($containerName) {
  @container #{$containerName} (min-width: #{(breakpoints.$largeBreakpoint - (breakpoints.$largeMargins * 2))}) {
    @content;
  }
}

@mixin extraLargeUp($containerName) {
  @container #{$containerName} (min-width: #{(breakpoints.$extraLargeBreakpoint - (breakpoints.$extraLargeMargins * 2))}) {
    @content;
  }
}
