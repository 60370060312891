@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/values";

.component {
    :global(.ArrowButton) {
        width: values.$px_32;
        height: values.$px_32;
        background-color: values.$colorNeutral800;
        :global(.qvIcon) {
            color: values.$colorWhite;
        }
    }
    :global(.ScrollIndicator) {
        display: none;
    }
}

.blogPostCard {
    display: flex;
    flex-direction: column;

    .blogPostImageWrapper {
        position: relative;
        min-height: 200px;
        .blogPostImage {
            border-radius: values.$radius6;
        }
    }

    .blogPostTitle {
        padding-top: values.$space5;
    }

    .blogPostCta {
        font-size: values.$px_14;
        font-weight: 700;
        display: flex;
        align-items: center;
        color: values.$colorRed500;
    
        .blogPostCtaIcon {
            color: values.$colorRed500;
        }
        .blogPostCtaText {
            text-decoration: underline;
            font-weight: 700;
        }
    }
}


