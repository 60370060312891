@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/breakpoints";
@use '$styles/mediaqueries';

.Row {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: auto 1fr;
  justify-content: center;
  column-gap: breakpoints.$extraSmallGutters;
  padding: 0 breakpoints.$extraSmallMargins;
  
  @include mediaqueries.smallUp {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    column-gap: breakpoints.$smallGutters;
    padding: 0 breakpoints.$smallMargins;
  }

  @include mediaqueries.mediumUp {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: breakpoints.$mediumGutters;
    padding: 0 breakpoints.$mediumMargins;
  }

  @include mediaqueries.largeUp {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: breakpoints.$largeGutters;
    padding: 0 breakpoints.$largeMargins;
  }

  @include mediaqueries.extraLargeUp {
    grid-template-columns: repeat(12, minmax(80px, 87px));
    column-gap: breakpoints.$extraLargeGutters;
    padding: 0 breakpoints.$extraLargeMargins;
  }
}


.Col {
  // non-specified bp columns - i.e. auto
  // default is mobile
  grid-column: auto / span 4;

  @include mediaqueries.smallUp {
    grid-column: auto / span 6;
  }

  @include mediaqueries.mediumUp {
    grid-column: auto / span 12;
  }
}

@mixin outputColRules($breakpointName, $maxCols) {
  // Widths
  @for $i from 1 through $maxCols {
    .#{$breakpointName}-#{$i} {
      grid-column-end: span $i;
    }
  }

  // starts
  @for $i from 1 through $maxCols {
    .#{$breakpointName}-start-#{$i} {
      grid-column-start: $i;
    }
  }
}

@include mediaqueries.extraSmallOnly {
  @include outputColRules('extraSmall', 4);
}

@include mediaqueries.smallUp {
  @include outputColRules('small', 6);
}

@include mediaqueries.mediumUp {
  @include outputColRules('medium', 12);
}

@include mediaqueries.largeUp {
  @include outputColRules('large', 12);
}

@include mediaqueries.extraLargeUp {
  @include outputColRules('extraLarge', 12);
}

// Order rules
@for $i from 1 through 12 {
  .Row .Col.col-order-#{$i} {
    order: $i;
  }
}