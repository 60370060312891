@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';

.component {
  height: 100%;
}

.map {
  width: 100%;
  height: 140px;
  transition: 0.3s;

  @include mediaqueries.mediumUp {
    height: 100%;
  }

  &.hidden {
    display: none;
  }

  &.open {
    height: 390px;
  }
}

.displayMapButton {
  position: absolute;
  transition: 0.3s;
  left: 50%;
  bottom: -20px;
  transform: translate(-50%, 0);

  @include mediaqueries.mediumUp {
    display: none;
  }
}

.loader {
  height: 140px;

  @include mediaqueries.mediumUp {
    height: 400px;
  }
}
