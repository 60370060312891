@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/fonts';
@use '$styles/variables/shadows';
@use '$styles/variables/spacings';
@use '$styles/variables/radii';
@use '$styles/tools/extends/typography';
@use '$styles/tools/mixins/typography' as typograghyMixins;
@use '$styles/mediaqueries';

.component {
  position: relative;
  padding-bottom: spacings.$S;
  overflow-x: hidden;
}

.contentsColumn {
  position: relative;
}

.card {
  position: relative;
  z-index: 1;
  background-color: colors.$white;

  @include mediaqueries.mediumOnly {
    max-width: 605px;
    margin-left: auto;
    margin-right: auto;
  }

  @include mediaqueries.largeUp {
    display: flex;
    flex-direction: row;
  }
}

.modal {
  h4 {
    color: colors.$brandDarkPurple;
  }

  @include mediaqueries.upToSmall {
    padding: 0 spacings.$M;
  }

  @include mediaqueries.smallUp {
    margin-top: spacings.$L;
  }

  @include mediaqueries.largeUp {
    max-width: 408px;
  }
}

.modalCard {
  padding: spacings.$S;
  margin-bottom: spacings.$XS;
  translate: 1px; // defeat weird overflow issue cropping out the borders
  background-color: colors.$white;
}

.modalItem {
  flex-wrap: wrap;
  row-gap: spacings.$XS;
}

.sentenceText {
  @extend %paragraphSTypography;
  flex: 1 0 100%;
  order: 3;
  color: colors.$neutral600;
}

.mainContent {
  display: flex;
  list-style: none;
  padding: spacings.$S;
  margin: 0;
  flex-direction: column;
  row-gap: spacings.$M;

  @include mediaqueries.largeUp {
    flex-direction: row;
    flex: 1;
    justify-content: space-around;
    column-gap: spacings.$L;
    padding: spacings.$S spacings.$M;
  }
}

.item {
  display: flex;
  flex-direction: row;
  column-gap: spacings.$S;
  align-items: center;
}

.icon {
  order: 1;
  width: 48px;
  height: 48px;
  padding: spacings.$XS;
  border-radius: 24px;
  color: colors.$purple500;
  background-color: colors.$purple200;

  &.modalIcon {
    width: 40px;
    height: 40px;
    padding: 8px;
  }

  &.adjust {
    @include mediaqueries.largeUp {
      width: 32px;
      height: 32px;
      padding: spacings.$XXXS;

      > :global(.qvIcon) {
        scale: 0.83334;
      }
    }
  }
}

.text {
  @extend %labelMTypographyStyles;
  margin: 0;
  order: 2;
  flex: 1 0;
  color: colors.$purple700;

  @include mediaqueries.largeUp {
    @include typograghyMixins.labelSTypographyStyles;
  }
}

.linkContainer {
  padding: spacings.$XS;
  text-align: center;
  border-radius: 0 0 radii.$M radii.$M;
  background-color: colors.$purple100;

  @include mediaqueries.largeUp {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: spacings.$S spacings.$M spacings.$S 0;
    background-color: transparent;
  }
}

.link {
  font-family: fonts.$headlinesFontFamily;
  color: colors.$purple500;
  padding: 0;

  @include mediaqueries.largeUp {
    text-wrap: nowrap;
  }
}

.green {
  $borderWidth: 2px;

  .icon {
    color: colors.$green600;
    background-color: colors.$green100;
    padding: spacings.$XS - $borderWidth;
    border: $borderWidth solid colors.$green200;

    @include mediaqueries.largeUp {
      padding: spacings.$XXXS - $borderWidth;
    }
  }

  .text {
    color: colors.$neutral600;
  }

  .linkContainer {
    @include mediaqueries.upToLarge {
      background-color: colors.$green100;
    }
  }

  .link {
    color: colors.$green500;
  }
}
