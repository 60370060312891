@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';

.topBackground {
  @include mediaqueries.mediumUp {
    background: url('https://res.cloudinary.com/fxtr/image/upload/landing/nut-header_ruvzu1.svg') no-repeat center 100%;
    background-position: 50% -10%;
    min-height: 600px;
  }
}
