@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/spacings';
@use '$styles/variables/colors';
@use '$styles/variables/radii';

$color1: colors.$brandPurple;
$color2: colors.$purple700;
$color3: colors.$brandBrightGreen;
$color4: colors.$brandCyan;

.component {
  background-color: colors.$white;

  .ServiceCard {
    cursor: pointer;
    > :global(.Card) {
      border: 1px solid transparent !important; // prevent shifting on hover
    }
    &:hover {
      > :nth-child(1) > :nth-child(1) {
        border: 1px solid colors.$neutral200 !important;
        border-bottom: 0 !important;
        box-shadow: 0 -1px 0 0 colors.$neutral200;
      }
      > :global(.Card) {
        background-color: colors.$neutral100;
        border: 1px solid colors.$neutral200 !important;
        box-shadow: 0 0 0 1px colors.$neutral200 !important;
        .cta {
          text-decoration: underline;
          transform: none;
          color: colors.$neutral600;
          text-decoration-color: colors.$neutral600;
        }
      }
    }
    > :global(.Card) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: spacings.$XS 0 spacings.$S;
      &:hover {
        .cta > div > div {
          &::before {
            background-color: colors.$neutral100 !important;
          }
        }
      }
      @include mediaqueries.largeUp {
        padding: spacings.$S 0 spacings.$M;
      }

      :global(.h5) {
        color: colors.$purple800;
        text-align: center;
        white-space: pre-wrap;
        margin-bottom: spacings.$XXXS;
        @include typography.labelLTypographyStyles;
        font-weight: 600;
        @include mediaqueries.largeUp {
          white-space: normal;
          @include typography.headlinesH5TypographyStyles;
          word-spacing: normal;
          margin-bottom: spacings.$XXS;
        }
        .headingIcon {
          margin: 0 auto;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 24px;
          border-radius: 50%;
          color: colors.$white;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: spacings.$S;
        }
        .headingIconDesktop {
          display: none;
          @include mediaqueries.largeUp {
            display: flex;
            width: 56px !important;
            height: 56px !important;
            background-size: 32px;
          }
        }
        .headingIconMobile {
          width: 48px !important;
          height: 48px !important;
          background-size: 24px;
          @include mediaqueries.largeUp {
            display: none;
          }
        }
      }
    }
  }
}
.heading {
  color: colors.$purple800;
  text-align: center;
  margin: spacings.$XS 0 spacings.$M;
  @include typography.headlinesH3TypographyStyles;
  
  @include mediaqueries.largeUp {
    @include typography.headlinesH1TypographyStyles;
    margin: spacings.$S 0 spacings.$XXL;
  }
}
.headingFr {
  margin-bottom: spacings.$XL;
}
.serviceCardCol {
  @include mediaqueries.upToLarge {
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-bottom: spacings.$S;
    }
  }
}

.serviceCard1 :global(.h5 > .qvIcon) {
  background-color: $color1;
}
.serviceCard2 :global(.h5 > .qvIcon) {
  background-color: $color2;
}
.serviceCard3 :global(.h5 > .qvIcon) {
  background-color: $color3;
}
.serviceCard4 :global(.h5 > .qvIcon) {
  background-color: $color4;
}
.price {
  text-align: center;
  color: colors.$neutral400;
  margin-bottom: spacings.$XS;
  height: 20px;
  @include typography.paragraphSTypography;
  @include mediaqueries.largeUp {
    @include typography.paragraphMTypography;
    margin-bottom: spacings.$S;
    height: 24px;
  }
}
.cta {
  padding: 0 !important;
  .ctaDesktop {
    display: none;
    @include mediaqueries.largeUp {
      display: block;
    }
  }
  .ctaMobile {
    color: colors.$neutral600;
    @include mediaqueries.largeUp {
      display: none;
    }
  }
  > div {
    @include mediaqueries.largeUp {
      color: colors.$neutral600;
      background-image: url('https://res.cloudinary.com/fxtr/image/upload/v1715614134/landing/icons/auurpe3i8k0piwlx9edu.svg');
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: 100% 0;
      font-weight: 600;
      font-size: 18px;
      padding-right: 32px;
      background-size: 24px;
      height: 24px;
    }
  }
}

:export {
  color1: $color1;
  color2: $color2;
  color3: $color3;
  color4: $color4;
}
