@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';
@use '$styles/variables/colors';
@use '$styles/variables/shadows';
@use '$styles/variables/fonts';

.component {
  background: colors.$white;
  // box-shadow: shadows.$elevationLevel1BoxShadow;
  // padding: spacings.$S spacings.$M spacings.$XS;
  background-color: colors.$white;
}

.servicesIntro {
  color: colors.$neutral400;
}

.servicesContainer {
  overflow: hidden;
  height: 500px;
  @include mediaqueries.mediumUp {
    display: flex;
    flex-wrap: wrap;
    column-gap: spacings.$XL;
    row-gap: spacings.$XS;
    height: 200px;
  }
  margin-bottom: spacings.$S;;
}

.Visible {
  height: 100%;
}

.Hidden {
  height: 0px;
  overflow: hidden;
}
