@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/radii';
@use '$styles/variables/shadows';
@use '$styles/mediaqueries';

.Card {
  box-sizing: border-box;
}

$i: 1;
@each $radius in radii.$list {
  .border-#{$i} {
    border-radius: $radius;
  }
  $i: $i + 1;
}

.elevation {
  &-1 {
    box-shadow: shadows.$elevationLevel1BoxShadow;
  }
  &-2 {
    box-shadow: shadows.$elevationLevel2BoxShadow;
  }
  &-3 {
    box-shadow: shadows.$elevationLevel3BoxShadow;
  }
  &-4 {
    box-shadow: shadows.$elevationLevel4BoxShadow;
  }
}
