@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';

.component {
    height: 100%;
    min-height: 140px;

    @include mediaqueries.upToSmall {
        width: 100vw;
    }
    position: relative;
}

.OpenMapContainer {
    height: 77px;
    width: 100%;
    background-position: right;

    .OpenMapButton {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        z-index: 999;
    }
}

.MapContainer {
    position: relative;
    height: 100%;

    .MapBackButton {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 999;
    }
}

.PostcodeAutocompleteContainer {
    position: absolute;
    top: 10px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    @include mediaqueries.mediumUp {
        top: 20px;
        width: 500px;
    }
}