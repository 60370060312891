@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use "$styles/variables/elevation";
@use '$styles/mediaqueries';
@use "$styles/tools/extends/typography";

.component {
	width: 100%;
	height: 56px;

	@include mediaqueries.smallUp {
		// space for .sticky
		height: 80px;
	}

	:global {
		.VrmLookupModal-toggle {
			display: none;

			@include mediaqueries.smallUp {
				display: inline-flex;
			}
		}
	}
}

.callUsCta {
	min-width: 80px;
	a {
		padding: 0 !important;
	}
}

.toggleButton {
	display: none !important;

	@include mediaqueries.smallUp {
		display: inline-flex !important;
	}
}
