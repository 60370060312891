@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';

.component {
  background-color: colors.$red100;
  margin: 32px auto;
  padding: 32px;
  border: 4px dashed colors.$red300;
  border-radius: 10px;

  > h3 {
    margin-top: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
      display: flex;
      gap: 10px;
    }
  }
}
