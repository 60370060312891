@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/colors';
@use '$styles/variables/spacings';
@use '$styles/variables/fonts';

.component {
  &.boxed {
    padding-top: spacings.$M + spacings.$L;
    background-color: colors.$white;

    @include mediaqueries.smallUp {
      padding-top: spacings.$L;
    }
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    color: colors.$neutral400;
    margin-bottom: 40px;

    @include mediaqueries.mediumUp {
      display: block;
      max-width: 750px;
    }

    p {
      margin: spacings.$M auto;
    }

    b {
      @include typography.labelLTypographyStyles;
      font-weight: 600;
      color: colors.$neutral600;
    }

    i {
      font-style: normal;
      color: colors.$purple500;
    }
  }

  .heading {
    @include typography.headlinesH4TypographyStyles;
    margin-bottom: spacings.$M;
    max-width: 1000px;

    @include mediaqueries.smallUp {
      @include typography.headlinesH3TypographyStyles;
      margin-bottom: spacings.$M;
    }
  }

  table {
    width: 100%;
  }

  .table {
    @include mediaqueries.upToSmall {
      width: calc(100% + spacings.$XL);
      translate: -20px;
    }
  }

  th,
  td {
    padding: 16px 20px;

    @include mediaqueries.smallUp {
      padding: 16px 24px;
    }
  }

  th {
    background-color: colors.$purple600;
    color: colors.$white;
    font-size: 18px;
    font-weight: 600;
    font-family: fonts.$headlinesFontFamily;

    @include mediaqueries.smallUp {
      font-size: 22px;
    }
  }

  tr:nth-child(odd) {
    background-color: colors.$neutral100;

    @include mediaqueries.smallUp {
      background-color: colors.$purple100;
    }
  }

  tr:nth-child(2) td {
    padding-top: 32px;
  }

  .getQuote {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .footerText {
    text-align: center;
    font-weight: 600;
    font-family: fonts.$headlinesFontFamily;
    font-size: 18px;
    margin-top: 16px;
    color: colors.$neutral800;
    max-width: none;
  }
}

.leftCol {
  width: 60%;
  text-align: left;

  @include mediaqueries.smallUp {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}

.rightCol {
  width: 40%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;

  @include mediaqueries.smallUp {
    font-size: 18px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  @include mediaqueries.mediumUp {
    text-align: right;
  }
}
/*
* We want the data from pricing tables be picked up by Google and used as rich snippets, 
* so we use an ::after pseudo-element to make the Save label visible for humans but 
* invisible for search engines.
*/
.priceRange::after {
  content: attr(data-content);
  background: colors.$green300;
  height: 20px;
  line-height: 20px;
  padding: 0 10px;
  color: colors.$white;
  font-size: 12px;
  font-family: fonts.$headlinesFontFamily;
  letter-spacing: 0.6px;
  border-radius: 12px;
  display: table;
  border-collapse: separate;
  border-spacing: 2px 0;
  margin: 0 auto;

  @include mediaqueries.mediumUp {
    display: inline-block;
    margin-left: 12px;
  }
}
.contents {
  max-width: 659px;
  margin: auto;
}
.toggleLink {
  text-align: center;
  a.toggleAnchor {
    &:link,
    &:visited,
    &:hover,
    &:active {
      &[href] {
        color: colors.$neutral400;
        cursor: pointer;
      }
    }
  }
}
