@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';
@use '$styles/tools/mixins/utils';
@use '$styles/variables/colors';

.component {
  background: colors.$white;

  aside {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    background: colors.$neutral100;
    border-radius: 24px;

    a {
      @include utils.anchorColor(colors.$neutral800);
      &:hover {
        color: colors.$purple600;
        text-decoration: underline;
      }

      &:not(:last-child) {
        padding-bottom: 16px;
        border-bottom: 1px solid colors.$neutral200;
      }
    }
  }

  h2 {
    @include typography.headlinesH4TypographyStyles;
    text-align: center;
    margin-bottom: 32px;

    @include mediaqueries.largeUp {
      @include typography.headlinesH2TypographyStyles;
      margin-bottom: 52px;
    }
  }

  h3 {
    display: none; // on mobile, H3 are showed as Accordion titles
    @include mediaqueries.largeUp {
      display: block;
      @include typography.headlinesH4TypographyStyles;
    }
  }

  h4 {
    @include typography.labelMTypographyStyles;
    @include mediaqueries.largeUp {
      @include typography.headlinesH5TypographyStyles;
    }
  }
  h5 {
    @include typography.labelSTypographyStyles;
    @include mediaqueries.largeUp {
      @include typography.headlinesH6TypographyStyles;
    }
  }

  p {
    color: colors.$neutral400;
  }

  :global {
    .ant-collapse-content-box {
      padding: 16px 0 0 0 !important;
      ul {
        padding-left: 30px;
      }
    }
  }
}