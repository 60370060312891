@use "$styles/variables/colors";

@keyframes skeletonPulse {
  0% {
    filter: brightness(1.08);
  }
  100% {
    filter: brightness(1);
  }  
}

@mixin textBarSkeleton($width: 70px) {
  &::after {
    content: "";
    display: block;
    width: $width;
    height: 16px;
    background-color: colors.$purple300;
  
    animation: skeletonPulse 1000ms ease-out 0s infinite alternate;
  }
}

@mixin paragraphSkeleton() {
  &::after {
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua";
    display: inline;
    color: transparent;
    line-height: 36px;
    background-color: colors.$purple300;

    animation: skeletonPulse 1000ms ease-out 0s infinite alternate;
  }
}

@mixin radioButtonContentSkeleton {
  content: "";
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: colors.$purple300;

    animation: skeletonPulse 1000ms ease-out 0s infinite alternate;
}

/* @NOTE only useable with src/components/atoms/Buttons component */
@mixin buttonSkeleton($width: 100%) {
  &::after {
    content: "Lorem ipsum dolor";
    display: flex;
    width: $width;
    height: 100%;
    color: transparent;
    background-color: colors.$purple300;

    animation: skeletonPulse 1000ms ease-out 0s infinite alternate;
  }
}
