@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/breakpoints';
@use '$styles/variables/spacings';
@use '$styles/tools/mixins/typography';
@use '$styles/mediaqueries';
@use '$styles/containerqueries';

.component {
  color: colors.$white;
}

.componentCol {
  @include containerqueries.makeContainer(calloutColumn);
  @include containerqueries.largeUp(calloutColumn) {
    padding: 0 64px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 72px 20px;
  row-gap: 32px;
  position: relative;
  overflow: hidden;
  background-color: colors.$purple500;
  color: colors.$white;

  @include containerqueries.makeContainer(calloutCard);

  @include containerqueries.largeUp(calloutColumn) {
    padding: spacings.$XXL;
    row-gap: 106px;
  }
}

.heading {
  &:global(.HeadingLevels) {
    margin: 0;
    color: inherit;

    @include containerqueries.smallUp(calloutCard) {
      max-width: 555px;
    }

    @include containerqueries.upToMedium(calloutCard) {
      @include typography.headlinesH3TypographyStyles;
    }
  }
}

.contents {
  display: flex;
  flex-direction: inherit;
  row-gap: 56px;
  column-gap: 24px;
  color: inherit;
  position: relative;

  @include containerqueries.largeUp(calloutColumn) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
}

.ctas {
  display: flex;
  flex-direction: row;
  row-gap: 24px;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  padding-top: 15px;

  @include containerqueries.largeUp(calloutColumn) {
    padding-top: 0;
    flex: 0 1 312px;
  }
}

.body {
  @include typography.labelLTypographyStyles;
  font-weight: 600;

  @include containerqueries.largeUp(calloutColumn) {
    max-width: 400px;
    text-align: left;
  }
}

.mainCta {
  z-index: 1;
  @include containerqueries.upToLarge(calloutColumn) {
    width: 100%;
  }
  @include containerqueries.largeUp(calloutCard) {
    flex: 0 1 auto;
  }
}

.lines {
  position: absolute;
  z-index: 0;

  @include containerqueries.upToLarge(calloutColumn) {
    transform: translate(25%, -25%) scale(0.5);
    right: 40%;
    top: -34%;
  }

  @include containerqueries.largeUp(calloutColumn) {
    right: 96px;
    top: -118px;
  }
}
