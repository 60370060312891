@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/tools/mixins/typography';
@use '$styles/variables/colors';
@use '$styles/variables/spacings';

.GarageDetails {
  display: flex;
  margin-bottom: spacings.$XS;
}

.GarageIcon {
  margin-right: spacings.$XS;
}

.GarageText {
  @include typography.paragraphSTypography;

  color: colors.$neutral600;
  margin: 0;
}
