@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use "$styles/tools/mixins/typography";

.cta {
  @include typography.paragraphLTypography;

  @include mediaqueries.mediumUp {
    @include typography.headlinesH6TypographyStyles;
  }
}
