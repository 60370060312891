$elevationModals: 10000;
$elevationTop: 100;
$elevation0: 0;
$elevation1: 10;
$elevation2: 20;
$elevation3: 30;
$elevation4: 40;


:export {
  elevationModals: $elevationModals;
  elevationTop: $elevationTop;
  elevation0: $elevation0;
  elevation1: $elevation1;
  elevation2: $elevation2;
  elevation3: $elevation3;
  elevation4: $elevation4;
}

// z-index elevations

$modals: $elevationModals;
$top: $elevationTop;
$level0: $elevation0;
$level1: $elevation1;
$level2: $elevation2;
$level3: $elevation3;
$level4: $elevation4;
