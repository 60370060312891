@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/spacings';

.form {
  z-index: 10;
  display: flex;
  flex-direction: column;
  position: relative;

  :global(.inputError) {
    outline-color: red;
    border-color: red;
    transition:
      border-color 0.3s ease,
      outline-color 0.3s ease;
  }
}

.inputContainer {
  position: relative;
  margin-bottom: spacings.$M;
}

.icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: colors.$neutral400;
  transition: color 0.3s ease;
}

.iconFocused {
  color: colors.$purple500;
}

.input {
  width: stretch;
  border-radius: 40px;
  height: 64px;
  padding: 0 20px 0 58px;
  border: 2px solid colors.$purple300;
  font-size: 18px;
  color: colors.$neutral800;
  font-weight: 600;
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease,
    outline-color 0.3s ease;

  &:focus {
    outline: none;
    outline-color: colors.$purple500;
    border-color: colors.$purple500;
    box-shadow: 0 0 0 2px rgba(16, 130, 234, 0.2);
  }

  &::placeholder {
    font-size: 18px;
    font-weight: normal;
    color: colors.$neutral400;
    opacity: 1;
  }
}

.postcodeAutocomplete {
  margin-bottom: spacings.$M;
}

.errorMessage {
  font-size: 0.86rem;
  line-height: normal;
  text-align: left;
  color: colors.$red200;
  position: absolute;
  bottom: -21px;
  height: 20px;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s,
    opacity 0.3s ease;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
}

.errorMessage.visible {
  visibility: visible;
  opacity: 1;
}

.button {
  z-index: 1;
}

.PoweredBy {
  margin-top: spacings.$S;
}