@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/colors';

.component {
}
.logoDivider {
  text-align: center;
  border: 0;
  border-top: 1px solid colors.$purple300;
  overflow: visible;
  margin: 24px 0 -30px;
  @include mediaqueries.mediumUp {
    margin: 28px 0 -24px;
  }
}
.logoDivider::after {
  content: '';
  background-image: url('https://res.cloudinary.com/fxtr/image/upload/v1715704709/landing/logo/salehzzlztouukchcoai.svg');
  background-size: 48px;
  background-repeat: no-repeat;
  background-position-x: 50%;
  display: inline-block;
  margin-top: -24px;
  width: 66px;
  height: 70px;
  background-color: colors.$white;
  @include mediaqueries.mediumUp {
    margin-top: -28px;
    background-size: 56px;
    width: 76px;
  }
}
