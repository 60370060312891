@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/colors';

.component {
  background: linear-gradient(180deg, #3c2f6c 0%, #170c42 100%);
  padding: 40px 0;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    > li {
      margin-bottom: 8px;
      line-height: 16px;
    }
  }

  a {
    font-family: 'Rubik';
    font-size: 12px;
    line-height: 20px;
    display: inline-block;
    &,
    &:hover,
    &:visited {
      color: colors.$white;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  h3 {
    color: colors.$white;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 28px;

    @include mediaqueries.mediumUp {
      font-size: 18px;
      line-height: 20px;
    }
  }

  p {
    margin: 0;
  }
}

.col {
  margin-bottom: 25px;
}

.meetFixter {
  order: 1;
  @include mediaqueries.smallUp {
    grid-row: 1/3;
  }
}

.joinUs {
  order: 2;

  @include mediaqueries.smallUp {
    grid-row: 3/6;
  }
}

.popularServices {
  order: 3;
  grid-row: 2/7;

  @include mediaqueries.smallUp {
    grid-row: 1/6;
  }
}

.terms {
  order: 4;

  @include mediaqueries.smallUp {
    grid-row: 1/2;
  }
}

.garages {
  order: 5;

  @include mediaqueries.smallUp {
    grid-row: 2/3;
  }
}

.paymentMethods {
  order: 6;

  @include mediaqueries.smallUp {
    grid-row: 3/4;
  }

  > ul {
    display: flex;
    gap: 8px;
    > li {
      margin: 0;
      line-height: 0;
    }
  }
}

.callUs {
  order: 7;

  @include mediaqueries.smallUp {
    grid-row: 4/6;
  }
}

.partOfRenault {
  order: 8;
}

.footerEnd {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mediaqueries.smallUp {
    flex-direction: row;
  }

  @include mediaqueries.mediumUp {
    justify-content: space-between;
  }

  .socialMedia {
    text-align: center;
    > ul {
      display: flex;
      width: fit-content;
      margin: 40px auto;
      > li {
        display: inline-block;
        margin: 0 8px;
        line-height: 0;
        > a {
          line-height: 0;
        }
      }
    }

    @include mediaqueries.mediumUp {
      order: 2;
      > ul {
        margin: 0;
      }
    }
  }

  .copyright {
    font-size: 10px;
    color: colors.$white;

    @include mediaqueries.mediumUp {
      order: 1;
    }
  }
}
