@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/spacings';
@use '$styles/variables/colors';

.component {
  display: flex;
  padding: spacings.$M spacings.$M spacings.$M spacings.$M;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: spacings.$S;
}

.social-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: spacings.$M;
  align-self: stretch;
}

.social-icon-container {
}

.social-icon-link {
  color: colors.$white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: var(--primary-color, colors.$yellow300);
}
